<template>
  <v-autocomplete
    v-model="containerId"
    :items="containersWithCurrentOne"
    label="Nr kontenera"
    item-text="refNumberWithType"
    item-value="id"
    placeholder="Nr kontenera"
    outlined
    dense
    :clearable="isClearanceAllowed"
    @input="$emit('update', { containerId: $event })"
    @click:clear="clearContainer"
  />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import get from 'lodash/get'

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    containerObject: {},
    containerId: null
  }),
  computed: {
    ...mapState({
      containersList: state => state.containers.simplifiedList,
      department: state => state.core.department,
    }),
    isClearanceAllowed () {
      return this.data?.courses?.[0]?.courseStatus !== 'Wykonany'
    },
    containersWithCurrentOne () {
      const { containerObject, containersList } = this
      return containerObject?.id ? [containerObject, ...containersList] : containersList
    }
  },
  mounted () {
    this.getContainers({
      params: {
        departmentId: this.department.id,
        available: true,
        active: true,
      }
    })
    this.containerObject = get(this.data, 'container')
    const { id } = get(this.data, 'container') || {}
    this.containerId = id
    this.$emit('update', { containerId: id })
  },
  methods: {
    ...mapActions({
      getContainers: 'containers/getSimplifiedItems'
    }),
    clearContainer () {
      this.containerId = null
      this.$nextTick(() => { this.$emit('update', { containerId: null }) })
    }
  }
}
</script>
